import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Flex, Text } from "theme-ui";
import { Tablet } from "@brinks/common/Icons/Components/tablet";
import TagManager from "react-gtm-module";
import PageHeader from "../../Shared/PageHeader";

export default function DeviceManagement() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Device management | Start screen",
      },
    });
  }, []);

  const { t } = useTranslation();

  const handleLinkClick = (url: string, eventAction: string) => {
    window.open(url, "_blank");
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Device management",
        Action: eventAction,
      },
    });
  };

  return (
    <>
      <PageHeader>
        <Heading>{t("DeviceManagement.heading")}</Heading>
      </PageHeader>

      <Box
        bg="white"
        data-testid="PageHeader"
        py={[20, 50]}
        px={[3, 4, 5]}
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          my: 124,
          mx: 24,
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            gap: [3, 4],
          }}
        >
          <Flex
            onClick={() =>
              handleLinkClick(
                "https://cas.lincsafe.com/",
                "Click_Lincsafe_Version2"
              )
            }
            sx={{
              width: ["100%", "430px"],
              height: ["auto", "180px"],
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: [3, 4],
              backgroundColor: "white",
              cursor: "pointer",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: ["column", "row"],
            }}
          >
            <Tablet
              color="order_primary"
              secondaryColor="order_secondary"
              size={48}
            />
            <Flex
              ml={[0, 3]}
              sx={{
                flexDirection: "column",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Text
                sx={{
                  fontSize: ["small", "medium"],
                  fontFamily: "heading",
                  fontWeight: "heading",
                  color: "black",
                }}
              >
                {t("DeviceManagement.v2_label")}
              </Text>
              <Text
                mt={[2, 3]}
                sx={{
                  fontSize: ["small", "subText"],
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  color: "shade_400",
                }}
              >
                {t("DeviceManagement.v2_description")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            onClick={() =>
              handleLinkClick(
                "https://brinks.lincsafe.com/",
                "Click_Brinks_Version4"
              )
            }
            ml={[0, 4]}
            sx={{
              width: ["100%", "434px"],
              height: ["auto", "180px"],
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: [3, 4],
              backgroundColor: "white",
              cursor: "pointer",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: ["column", "row"],
            }}
          >
            <Tablet
              color="order_primary"
              secondaryColor="order_secondary"
              size={48}
            />
            <Flex
              ml={[0, 3]}
              sx={{
                flexDirection: "column",
                textAlign: "left",
                flex: 1,
              }}
            >
              <Text
                sx={{
                  fontSize: ["small", "medium"],
                  fontFamily: "heading",
                  fontWeight: "heading",
                  color: "black",
                }}
              >
                {t("DeviceManagement.v4_label")}
              </Text>
              <Text
                mt={[2, 3]}
                sx={{
                  fontSize: ["small", "subText"],
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  color: "shade_400",
                }}
              >
                {t("DeviceManagement.v4_description")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
